import MemberLayout from '../views/member/Layout.vue';
import LazyLoad from '@/services/LazyLoad'
import RouterLogic from "@/logics/RouterLogic";
import AuthLogic from "mg-js-core/lib/logics/AuthLogic";
// import AuthLogic from "mg-js-core/lib/logics/AuthLogic";

export default [
    {
        path: '/',
        component: MemberLayout,
        name: 'member-layout',
        beforeEnter: (to, from, next) => {
            RouterLogic.redirectMember(to, from, next)
        },
        children: [
            {
                path: 'dashboard',
                name: 'member-dashboard',
                component: LazyLoad.localView('member/Dashboard'),
            },
            {
                path: 'profile/:profileTab?',
                name: 'member-profile',
                component: LazyLoad.localView('member/user/UserProfile'),
            },
            {
                path: 'notifications',
                name: 'member-notifications',
                component: LazyLoad.localView('member/user/UserNotificationsList'),
            },
            {
                path: 'courses/:courseTab?',
                name: 'member-course-list',
                component: LazyLoad.localView('member/course/CoursesList'),
            },
            {
                path: 'courses/details/:courseUuid',
                name: 'member-course-detail',
                component: LazyLoad.localView('member/course/CourseDetail'),
            },
            {
                path: 'courses/:courseUuid',
                name: 'member-course-show',
                component: LazyLoad.localView('member/course/CoursesShow'),
            },
            {
                path: 'courses/:courseUuid/:courseModuleUuid',
                name: 'member-course-module-show',
                component: LazyLoad.localView('member/course/CoursesShow'),
            },
            {
                path: '/courses/:courseUuid/courses-modules/:courseModuleUuid/:lessonUuid?/:tab?',
                name: 'member-lesson-show',
                component: LazyLoad.localView('member/course/LessonsShow'),
            },
            {
                path: 'progression',
                name: 'member-progression',
                component: LazyLoad.localView('member/course/Progression'),
            },
            {
                path: 'community',
                name: 'member-community-summary',
                component: LazyLoad.localView('member/community/CommunitySummary'),
            },
            {
                path: 'vip',
                name: 'member-vip-summary',
                // beforeEnter: (to, from, next) => {
                //     if(AuthLogic.me().user_courses_vip_count){
                //         next()
                //     }else{
                //         next({name : 'member-course-list'})
                //     }
                // },
                // component: LazyLoad.localView('member/vip/VipSummary'),
                component: LazyLoad.localView('member/vip/VipSummary'),
            },
            {
                path: 'chat-beta/:roomUuid?',
                name: 'member-community-chat',
                component: LazyLoad.localView('member/community/CommunityChat'),
            },
            {
                path: 'products',
                name: 'member-product-list',
                // component: UserEditView,
                component: LazyLoad.localView('member/cart/ProductList')
            },
            {
                path: 'songs',
                name: 'member-songs-list',
                // component: UserEditView,
                component: LazyLoad.localView('member/cart/ProductList')
            },
            {
                path: 'cart/vip/:cartFunnelStep?',
                name: 'member-cart-vip',
                // component: UserEditView,
                props: true,
                beforeEnter: (to, from, next) => {
                    const user = AuthLogic.me();

                    if (!user.user_courses_count) {
                        next({
                            name : 'member-product-list'
                        })
                    } else {
                        next()
                    }
                },
                component: LazyLoad.localView('member/cart/CartFunnelVip')
            },
            {
                path: 'cart/:productUuid/:cartFunnelStep?',
                name: 'member-cart',
                // component: UserEditView,
                props: true,

                component: LazyLoad.localView('member/cart/CartFunnel')
            },
            {
                path: '/forum',
                name: 'forum',
                component: LazyLoad.localView('member/Forum')
            },
            {
                path: '/map',
                name: 'map',
                component: LazyLoad.localView('member/Map')
            },
            {
                path: '/support',
                name: 'support',
                component: LazyLoad.localView('member/support/SupportHome')
            },
            {
                path: '/support/contact',
                name: 'support-contact',
                component: LazyLoad.localView('member/support/Contact')
            },
            {
                path: '/support/:category',
                name: 'support-category',
                component: LazyLoad.localView('member/support/SupportCategory')
            },
            {
                path: '/support/article/:id',
                name: 'support-article',
                component: LazyLoad.localView('member/support/SupportArticle')
            },
            {
                path: 'user/:userUuid',
                name: 'member-user-detail',
                component: LazyLoad.localView('member/user/UserDetail'),
            },
            {
                path: 'videos',
                name: 'member-video',
                component: LazyLoad.localView('member/video/Video'),
            },
            {
                path: 'profile/quests',
                name: 'member-quest-list',
                component: LazyLoad.localView('member/quest/QuestsList'),
            },
            {
                path: 'club/offers',
                name: 'member-club-offers',
                component: LazyLoad.localView('member/club/Offers'),
            },
            {
                path: 'live-hebdo',
                name: 'member-live-hebdo',
                component: LazyLoad.localView('member/LiveHebdo'),
                beforeEnter: (to, from, next) => {
                    if (process.env.VUE_APP_PLATFORM_CURRENT === 'MYPIANOPOP') {
                        next({
                            name: 'member-dashboard',
                        })
                    } else {
                        next()
                    }
                },
            },
            {
                path: 'repertory/course-module/:courseModuleSlug/lesson/:lessonSlug',
                name: 'repertory-lesson',
                component: LazyLoad.localView('member/repertory/Lesson'),
            },
            {
                path: '/account-deletion/:userUuid/:token',
                name: 'account-deletion',
                component: LazyLoad.localView('member/user/AccountDeletion'),
            },
        ],
    }
]
